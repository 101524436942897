import React, { FC, useState, useEffect } from "react";
import AnyReactComponent from "components/AnyReactComponent/AnyReactComponent";
import GoogleMapReact from "google-map-react";
import { DEMO_EXPERIENCES_LISTINGS } from "data/listings";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Checkbox from "shared/Checkbox/Checkbox";
import Pagination from "shared/Pagination/Pagination";
import TabFilters from "./TabFilters";
import Heading2 from "components/Heading/Heading2";
import ExperiencesCardH from "components/ExperiencesCardH/ExperiencesCardH";
import PersianNumber from "shared/localize/PersianNumber";
import momentJalaali from "moment-jalaali";
import moment from "moment";

const DEMO_EXPERIENCES = DEMO_EXPERIENCES_LISTINGS.filter((_, i) => i < 3);

export interface SectionGridHasMapProps {}

const SectionGridHasMap: FC<SectionGridHasMapProps> = () => {
  const [currentHoverID, setCurrentHoverID] = useState<string | number>(-1);
  const [showFullMapFixed, setShowFullMapFixed] = useState(false);

  useEffect(() => {
    momentJalaali.locale("fa");
    momentJalaali.loadPersian({
      dialect: "persian-modern",
      usePersianDigits: true,
    });
  });

  return (
    <div>
      {/* CARDSSSS */}
      <div className="relative min-h-screen pt-10 pb-24 lg:pb-28">
        <Heading2
          heading="تجربه های گردشگری گیلان، ماسال"
          subHeading={
            <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
              <PersianNumber className="font-bold">8</PersianNumber>{" "}
              <span className=" text-slate-500">تجربه</span>
              <span className="mx-2">·</span>
              <span className="mx-2 font-bold">از</span>
              <span className="font-light text-slate-500">
                {momentJalaali(moment()).format("jDD jMMMM")}
              </span>
              <span className="mx-2 font-bold">تا</span>
              <span className="font-light text-slate-500">
                {momentJalaali(moment().add(5, "d")).format("jDD jMMMM jYYYY")}
              </span>
              <span className="mx-2">·</span>
              <PersianNumber className="font-bold">2</PersianNumber>{" "}
              <span className="text-slate-500">نفر</span>
            </span>
          }
        />
        <div className="mb-8 lg:mb-11">
          <TabFilters />
        </div>
        <div className="grid grid-cols-1 gap-8">
          {DEMO_EXPERIENCES.map((item) => (
            <div
              key={item.id}
              onMouseEnter={() => setCurrentHoverID((_) => item.id)}
              onMouseLeave={() => setCurrentHoverID((_) => -1)}
            >
              <ExperiencesCardH data={item} />
            </div>
          ))}
        </div>
        <div className="flex items-center justify-center mt-16">
          <Pagination />
        </div>
      </div>
    </div>
  );
};

export default SectionGridHasMap;
