import React, { useEffect, useState } from "react";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import { FC } from "react";

export interface GuestsObject {
  guestAdults?: number;
}
export interface GuestInputSingleProps {
  defaultValue: GuestsObject;
  onChange?: (data: GuestsObject) => void;
  className?: string;
}

const GuestInputSingle: FC<GuestInputSingleProps> = ({
  defaultValue,
  onChange,
  className = "",
}) => {
  const [guestAdultsInputValue, setGuestAdultsInputValue] = useState(
    defaultValue.guestAdults || 0
  );

  useEffect(() => {
    setGuestAdultsInputValue(defaultValue.guestAdults || 0);
  }, [defaultValue.guestAdults]);

  const handleChangeData = (value: number) => {
    const newValue = {
      guestAdults: value,
    };
    setGuestAdultsInputValue(value);
    onChange && onChange(newValue);
  };

  return (
    <div className={`flex flex-col relative p-5 ${className}`}>
      <span className="block mb-5 text-xl font-semibold sm:text-2xl">
        چند نفر هستید؟
      </span>
      <NcInputNumber
        className="w-full"
        defaultValue={guestAdultsInputValue}
        onChange={handleChangeData}
        max={20}
        label="تعداد"
        desc="تعیین تعداد نفرات"
      />
    </div>
  );
};

export default GuestInputSingle;
