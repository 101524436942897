import React, { FC, useEffect, useState } from "react";
import ExperiencesSearchForm from "./ExperiencesSearchForm";
import StaySearchForm, { StaySearchFormFields } from "./StaySearchForm";
import RentalCarSearchForm from "./RentalCarSearchForm";
import FlightSearchForm from "./FlightSearchForm";

export type SearchTab = "Stays" | "Experiences" | "Cars" | "Flights";

export interface HeroSearchFormProps {
  className?: string;
  defaultTab?: SearchTab;
  onTabChange?: (tab: SearchTab) => void;
  defaultFieldFocus?: StaySearchFormFields;
}

const TABS: Record<SearchTab, { displayValue: string; isVisible: boolean }> = {
  Experiences: {
    displayValue: "انواع تورهای گردشگری و بومگردی",
    isVisible: true,
  },
  Stays: { displayValue: "ویلا و اقامتگاه", isVisible: false },
  Cars: { displayValue: "Cars", isVisible: false },
  Flights: { displayValue: "Flights", isVisible: false },
};

const HeroSearchForm: FC<HeroSearchFormProps> = ({
  className = "",
  defaultTab = "Experiences",
  onTabChange,
  defaultFieldFocus,
}) => {
  const [tabActive, setTabActive] = useState<SearchTab>(defaultTab);

  useEffect(() => {
    if (defaultTab === tabActive) {
      return;
    }
    setTabActive(defaultTab);
  }, [defaultTab]);

  const renderTab = () => {
    return (
      <ul className="h-[88px] flex justify-center space-x-5 sm:space-x-9">
        {Object.entries(TABS).map(([tab, { displayValue, isVisible }]) => {
          if (!isVisible) {
            return null;
          }
          const active = tab === tabActive;
          return (
            <li
              onClick={() => {
                setTabActive(tab as SearchTab);
                onTabChange && onTabChange(tab as SearchTab);
              }}
              className={`relative flex-shrink-0 flex items-center cursor-pointer text-base text-neutral-700 dark:text-neutral-300 ${
                active ? "text-neutral-900 dark:text-neutral-200" : ""
              } `}
              key={tab}
            >
              <div className="relative select-none">
                <span>{displayValue}</span>
                {active && (
                  <span className="absolute top-full mt-1 block w-full h-0.5 rounded-full bg-neutral-800 dark:bg-neutral-100 mr-2" />
                )}
              </div>
            </li>
          );
        })}
      </ul>
    );
  };

  const renderForm = () => {
    // switch (tabActive) {
    //   case "Stays":
    //     return <StaySearchForm defaultFieldFocus={defaultFieldFocus} />;
    //   case "Experiences":
    // return <ExperiencesSearchForm />;

    return <StaySearchForm defaultFieldFocus={defaultFieldFocus} />;
    //   case "Cars":
    //     return <RentalCarSearchForm />;
    //   case "Flights":
    //     return <FlightSearchForm />;

    //   default:
    //     return null;
    // }
  };

  return (
    <div
      className={`nc-HeroSearchForm ${className}`}
      data-nc-id="HeroSearchForm"
    >
      {renderTab()}
      <div className="mt-2">{renderForm()}</div>
    </div>
  );
};

export default HeroSearchForm;
