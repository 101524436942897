import { StarIcon } from "@heroicons/react/24/solid";
import React, { FC } from "react";
import PersianNumber from "shared/localize/PersianNumber";

export interface StartRatingProps {
  className?: string;
  point?: number;
  reviewCount?: number;
  renderReviewCount?: boolean;
}

const StartRating: FC<StartRatingProps> = ({
  className = "",
  point = 4.5,
  reviewCount = 112,
  renderReviewCount = true,
}) => {
  const reviewCountRender = () => {
    return (
      <span className="text-neutral-500 dark:text-neutral-400">
        (<PersianNumber>{reviewCount}</PersianNumber>)
      </span>
    );
  };

  return (
    <div
      className={`nc-StartRating flex items-center space-x-1 text-sm  ${className}`}
      data-nc-id="StartRating"
    >
      <div className="pb-[2px]">
        <StarIcon className="w-[18px] h-[18px] text-orange-500" />
      </div>
      <span className="font-medium ">
        <PersianNumber>{point.toString()}</PersianNumber>
      </span>
      {renderReviewCount && reviewCountRender()}
    </div>
  );
};

export default StartRating;
