import React, { FC } from "react";
import PropTypes from "prop-types";

const latinToPersianMap: string[] = [
  "۱",
  "۲",
  "۳",
  "۴",
  "۵",
  "۶",
  "۷",
  "۸",
  "۹",
  "۰",
];
const latinNumbers: RegExp[] = [
  /1/g,
  /2/g,
  /3/g,
  /4/g,
  /5/g,
  /6/g,
  /7/g,
  /8/g,
  /9/g,
  /0/g,
];
const arabicToPersianMap: string[] = ["۴", "۵", "۶"];
const arabicNumbers: RegExp[] = [/٤/g, /٥/g, /٦/g];

function latinToPersian(string: string): string {
  let result = string;

  for (let index = 0; index < 10; index++) {
    result = result.replace(latinNumbers[index], latinToPersianMap[index]);
  }

  return result;
}

function arabicToPersian(string: string): string {
  let result = string;

  for (let index = 0; index < 3; index++) {
    result = result.replace(arabicNumbers[index], arabicToPersianMap[index]);
  }

  return result;
}

function formatNumber(number: number, dec = false): string {
  const formattedNumber = number.toFixed(2);
  const [integerPart, decimalPart] = formattedNumber.split(".");
  const integerPartWithCommas = integerPart.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ","
  );

  return dec
    ? `${integerPartWithCommas}.${decimalPart}`
    : integerPartWithCommas;
}

function removeCommasFromString(string: string): string {
  return string.replace(/,/g, "");
}

const putSeparator = (numString: string, separator: string = ","): string => {
  if (typeof numString !== "string") return "";
  if (numString.length < 4) return numString;

  let result = "";
  for (let i = numString.length - 1, counter = 0; i >= 0; i--) {
    if (counter === 3) {
      result += separator;
      counter = 0;
    }
    result += numString[i];
    counter++;
  }

  result = result.split("").reverse().join("");
  return result;
};

interface PersianNumberProps {
  arabic?: boolean;
  latin?: boolean;
  separated?: boolean;
  children: React.ReactNode;
  format?: boolean;
  removeCommas?: boolean;
  className?: string;
}

const PersianNumber: FC<PersianNumberProps> = ({
  arabic = false,
  latin = true,
  separated = false,
  children,
  format = false,
  removeCommas = false,
  className = "",
}) => {
  const convert = (string: string): string => {
    let result = string;

    if (latin) {
      result = latinToPersian(string);
    }

    if (arabic) {
      result = arabicToPersian(result);
    }

    if (removeCommas) {
      result = removeCommasFromString(result);
    }

    if (format) {
      const numericValue = parseInt(result, 10);
      if (!isNaN(numericValue)) {
        result = formatNumber(numericValue, result.includes("."));
      }
    }

    if (separated) {
      result = putSeparator(result);
    }

    return result;
  };

  const convertedChildren = React.Children.map(children, (child) => {
    if (typeof child === "string") {
      return convert(child);
    } else if (typeof child === "number") {
      return convert(child.toString());
    }
    return child;
  });

  return <span className={className}>{convertedChildren}</span>;
};

PersianNumber.propTypes = {
  arabic: PropTypes.bool,
  latin: PropTypes.bool,
  separated: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.string,
  ]),
  format: PropTypes.bool,
  removeCommas: PropTypes.bool,
};

export default PersianNumber;
