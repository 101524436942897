import Label from "components/Label/Label";
import React from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import CommonLayout from "./CommonLayout";

const AccountPass = () => {
  return (
    <div>
      <CommonLayout>
        <div className="space-y-6 sm:space-y-8" style={{ minHeight: "700px" }}>
          {/* HEADING */}
          <h2 className="text-3xl font-semibold">تغییر کلمه عبور</h2>
          <div className="border-b w-14 border-neutral-200 dark:border-neutral-700"></div>
          <div className="max-w-xl space-y-6 ">
            <div>
              <Label>کلمه عبور فعلی</Label>
              <Input type="password" className="mt-1.5" />
            </div>
            <div>
              <Label>کلمه عبور جدید</Label>
              <Input type="password" className="mt-1.5" />
            </div>
            <div>
              <Label>تکرار کلمه عبور</Label>
              <Input type="password" className="mt-1.5" />
            </div>
            <div className="pt-2">
              <ButtonPrimary>به روز رسانی کلمه عبور</ButtonPrimary>
            </div>
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountPass;
