import Label from "components/Label/Label";
import React, { FC } from "react";
import Avatar from "shared/Avatar/Avatar";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import Textarea from "shared/Textarea/Textarea";
import CommonLayout from "./CommonLayout";
import { Helmet } from "react-helmet";

export interface AccountPageProps {
  className?: string;
}

const AccountPage: FC<AccountPageProps> = ({ className = "" }) => {
  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>جادونی | حساب کاربری</title>
      </Helmet>
      <CommonLayout>
        <div className="space-y-6 sm:space-y-8" style={{ minHeight: "700px" }}>
          {/* HEADING */}
          <h2 className="text-3xl font-semibold">حساب کاربری من</h2>
          <div className="border-b w-14 border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex flex-col md:flex-row">
            <div className="flex-grow max-w-3xl mt-10 space-y-6 md:mt-0">
              <div>
                <Label>نام و نام خانوادگی</Label>
                <Input className="mt-1.5" value="پدرام یکتازاده" />
              </div>
              {/* ---- */}
              <div>
                <Label>جنسیت</Label>
                <Select className="mt-1.5">
                  <option value="Male">مرد</option>
                  <option value="Female">زند</option>
                </Select>
              </div>

              {/* ---- */}
              <div>
                <Label>آدرس ایمیل</Label>
                <Input
                  className="mt-1.5 text-right"
                  value="pedram@jadooni.com"
                />
              </div>
              {/* ---- */}
              <div>
                <Label>شماره همراه</Label>
                <Input className="mt-1.5" value="09111111111" />
              </div>
              {/* ---- */}
              <div className="pt-2">
                <ButtonPrimary>به روز رسانی اطلاعات</ButtonPrimary>
              </div>
            </div>
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountPage;
