import React, { FC, useState } from "react";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import LocationMarker from "components/AnyReactComponent/LocationMarker";
import CommentListing from "components/CommentListing/CommentListing";
import FiveStartIconForRate from "components/FiveStartIconForRate/FiveStartIconForRate";
import GuestsInput from "components/HeroSearchForm/GuestsInput";
import StartRating from "components/StartRating/StartRating";
import GoogleMapReact from "google-map-react";
import useWindowSize from "hooks/useWindowResize";
import moment from "moment";
import {
  DayPickerSingleDateController,
  isInclusivelyAfterDay,
} from "react-dates";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonCircle from "shared/Button/ButtonCircle";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Input from "shared/Input/Input";
import NcImage from "shared/NcImage/NcImage";
import LikeSaveBtns from "./LikeSaveBtns";
import ModalPhotos from "./ModalPhotos";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import ExperiencesDateSingleInput from "components/HeroSearchForm/ExperiencesDateSingleInput";
import MobileFooterSticky from "./MobileFooterSticky";
import PersianNumber from "shared/localize/PersianNumber";

export interface ListingExperiencesDetailPageProps {
  className?: string;
}

const PHOTOS: string[] = [
  "https://images.kojaro.com/2020/6/149e4ff6-2971-4a3b-b0b6-2c8f43cd6a81-840x560.jpg",
  "https://images.kojaro.com/2020/6/a4f81f20-5e10-46d8-ba54-d81d3d0e8b0d-840x560.jpg",
  "https://images.kojaro.com/2020/6/eb78706a-6698-4a5b-ae10-c73e6cb8c4ae-840x560.jpg",
  "https://images.kojaro.com/2020/6/6beb11b2-eb8a-4f9e-9fa4-6834cf3c89b6-840x560.jpg",
];

const includes_demo = [
  { name: "Set Menu Lunch on boat" },
  { name: "Express Bus From Hanoi To Halong and Return" },
  { name: "Mineral Water On Express Bus" },
  { name: "Kayak or Bamboo Boat. Life Jacket." },
  { name: "Halong Bay Entrance Ticket" },
];

const ListingExperiencesDetailPage: FC<ListingExperiencesDetailPageProps> = ({
  className = "",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [openFocusIndex, setOpenFocusIndex] = useState(0);
  const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(
    moment().add(2, "days")
  );

  const windowSize = useWindowSize();

  const getDaySize = () => {
    if (windowSize.width <= 375) {
      return 34;
    }
    if (windowSize.width <= 500) {
      return undefined;
    }
    if (windowSize.width <= 1280) {
      return 56;
    }
    return 48;
  };

  const handleOpenModal = (index: number) => {
    setIsOpen(true);
    setOpenFocusIndex(index);
  };

  const handleCloseModal = () => setIsOpen(false);

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap !space-y-6">
        {/* 1 */}
        {/* <div className="flex items-center justify-between">
          <Badge color="blue" name="تور بومگردی" />
          <LikeSaveBtns />
        </div> */}

        {/* 2 */}
        <h2 className="text-2xl font-semibold sm:text-3xl lg:text-4xl">
          تور گردشگری عشایر ییلاق سوباتان
        </h2>

        {/* 3 */}
        <div className="flex items-center space-x-4">
          {/* <StartRating renderReviewCount={false} /> */}
          <Badge color="blue" name="تور بومگردی" />
          <span>
            <i className="las la-map-marker-alt"></i>
            <span className="ml-1"> گیلان، تالش</span>
          </span>
        </div>

        <div className="flex items-center">
          <span className="line-clamp-6">
            گر به دنبال تجربهای بینظیر از گردشگری در مناطق طبیعی و عشایری هستید،
            تور گردشگری عشایر سوباتان را از دست ندهید. این تور به شما فرصتی عالی
            میدهد تا به دنیایی از زیباییها و خاطرهسازیهای بینظیر دسترسی داشته
            باشید. با همراهی راهنمایان مجرب و حرفهای، سفری عمیق به ییلاقهای زیبا
            و خوشآبوهوا در سوباتان را تجربه خواهید کرد. این ییلاقها با پوشش
            شقایق و ابری، خاطره دلنشینی را برای شما به همراه خواهند داشت. در این
            تجربه گردشگری همراه با عشایر سوباتان، در کلبههای چوبی سنتی اقامت
            کرده و شبها را با تماشای آسمان پرستاره در کنار آتش سپری خواهید کرد.
            این لحظاتی است که هیچگاه فراموش نخواهید کرد. تجربه پیادهروی در
            ارتفاعات جنگل لیسار (آلچالیق) و بازدید از آبشارهای زیبا و بارش شهابی
            برساووشی، یکی از هیجانانگیزترین لحظات این سفر خواهد بود.
          </span>
        </div>

        {/* 4 */}
        <div className="flex items-center">
          <Avatar hasChecked sizeClass="h-10 w-10" radius="rounded-full" />
          <span className="ml-2.5 text-neutral-500 dark:text-neutral-400">
            برگزار کننده{" "}
            <span className="font-medium text-neutral-900 dark:text-neutral-200">
              پدرام یکتازاده
            </span>
          </span>
        </div>

        {/* 5 */}
        <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />

        {/* 6 */}
        <div className="flex items-center justify-between space-x-8 text-sm xl:justify-start xl:space-x-12 text-neutral-700 dark:text-neutral-300">
          <div className="flex flex-col items-center space-y-3 text-center sm:flex-row sm:space-y-0 sm:text-left sm:space-x-3 ">
            <i className="text-2xl las la-clock"></i>
            <span className="">
              <PersianNumber>4</PersianNumber> روز
            </span>
          </div>
          <div className="flex flex-col items-center space-y-3 text-center sm:flex-row sm:space-y-0 sm:text-left sm:space-x-3 ">
            <i className="text-2xl las la-user-friends"></i>
            <span className="">
              ظرفیت باقیمانده <PersianNumber>1</PersianNumber> نفر
            </span>
          </div>
          <div className="flex flex-col items-center space-y-3 text-center sm:flex-row sm:space-y-0 sm:text-left sm:space-x-3 ">
            <i className="text-2xl las la-language"></i>
            <span className="">فارسی</span>
          </div>
        </div>
      </div>
    );
  };

  const renderSection2 = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">جزئیات تجربه</h2>
        <div className="border-b w-14 border-neutral-200 dark:border-neutral-700"></div>
        <div className="text-neutral-6000 dark:text-neutral-300">
          <h3 className="font-semibold">روز 1</h3>
          <ul className="mb-4">
            <li>صبح زود: حرکت از رشت به هشپر</li>
            <li>پس از ورود به هشپر: انتقال به سوباتان با استفاده از پاترول</li>
            <li>صرف صبحانه در سوباتان</li>
            <li>بازدید از آبشار زیبای ورزان</li>
            <li>پیاده‌روی به اقامتگاه و صرف ناهار</li>
            <li>پیاده‌روی در جنگل آلچالیق و تماشای طبیعت زیبا</li>
            <li>
              بازگشت به اقامتگاه و زمان آزاد برای گشت در بازار سوباتان و بازدید
              از موزه مردم‌شناسی عشایری سوباتان
            </li>
            <li>شبنشینی در کلبه چوبی و تماشای آسمان پرستاره</li>
          </ul>

          <h3 className="font-semibold">روز 2</h3>
          <ul className="mb-4">
            <li>صبح زود: حمام تازه و نان محلی با عشایر</li>
            <li>صرف صبحانه در خانه محلی</li>
            <li>
              پیاده‌روی به ارتفاعات دره ساری داش (دره عقابها) و تماشای
              چشم‌اندازهای دلنشین
            </li>
            <li>بازگشت به خانه محلی و صرف ناهار</li>
            <li>حرکت با پاترول به هشپر</li>
            <li>بازگشت به رشت با خاطرات خوش از تور گردشگری عشایر سوباتان</li>
          </ul>

          <h4 className="font-semibold">جاذبه‌ها:</h4>
          <ol className="mb-4">
            <li>آبشار ورزان</li>
            <li>جنگل آلچالیق (تالش)</li>
            <li>بازار سوباتان و محصولات عشایر</li>
            <li>موزه مردم‌شناسی عشایری سوباتان</li>
            <li>گلزارها و سبزه‌زاران دامنه‌های کوههای تالش</li>
            <li>دره ساری داش (دره عقابها)</li>
            <li>بارش شهابی برساووشی در سوباتان</li>
          </ol>

          <p>
            نکته: آنتن دهی تلفن در منطقه ضعیف است، اما امکان برقراری تماس در
            ارتفاعات وجود دارد.
          </p>
        </div>
      </div>
    );
  };

  const renderSection3 = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">خدمات</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            خدماتی و موارد جانبی
          </span>
        </div>
        <div className="border-b w-14 border-neutral-200 dark:border-neutral-700"></div>
        {/* 6 */}
        <div className="grid grid-cols-1 gap-6 text-sm lg:grid-cols-2 text-neutral-700 dark:text-neutral-300 ">
          {/* {includes_demo
            .filter((_, i) => i < 12)
            .map((item) => (
              <div key={item.name} className="flex items-center space-x-3">
                <i className="text-2xl las la-check-circle"></i>
                <span>{item.name}</span>
              </div>
            ))} */}
          <div className="flex items-center space-x-3">
            <i className="text-2xl las la-check-circle"></i>
            <span>اقامت 1 شب در کلبه روستایی با امکانات کامل</span>
          </div>

          <div className="flex items-center space-x-3">
            <i className="text-2xl las la-check-circle"></i>
            <span>صبحانه، ناهار و شام به صورت وعده‌های غذایی سنتی محلی</span>
          </div>

          <div className="flex items-center space-x-3">
            <i className="text-2xl las la-check-circle"></i>
            <span>
              برنامه‌های تفریحی و مشاهده جاذبه‌ها با همراهی راهنمای مجرب
            </span>
          </div>

          <div className="flex items-center space-x-3">
            <i className="text-2xl las la-check-circle"></i>
            <span>پیاده‌روی‌های لذت‌بخش و تماشای چشم‌اندازهای خیره‌کننده</span>
          </div>

          <div className="flex items-center space-x-3">
            <i className="text-2xl las la-check-circle"></i>
            <span>بازدید از موزه مردم‌شناسی عشایری با آثار تاریخی باستانی</span>
          </div>
        </div>
      </div>
    );
  };

  const renderSectionCheckIndate = () => {
    return (
      <div className="overflow-hidden listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Availability</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Prices may increase on weekends or holidays
          </span>
        </div>
        <div className="border-b w-14 border-neutral-200 dark:border-neutral-700"></div>
        {/* CONTENT */}

        <div className="flow-root listingSection__wrap__DayPickerRangeController">
          <div className="-mx-4 sm:mx-auto xl:mx-[-22px]">
            <DayPickerSingleDateController
              date={selectedDate}
              onDateChange={(date) => setSelectedDate(date)}
              onFocusChange={() => {}}
              focused
              initialVisibleMonth={null}
              numberOfMonths={windowSize.width < 1280 ? 1 : 2}
              daySize={getDaySize()}
              hideKeyboardShortcutsPanel
              isOutsideRange={(day) => !isInclusivelyAfterDay(day, moment())}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderSection5 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">برگزار کننده</h2>
        <div className="border-b w-14 border-neutral-200 dark:border-neutral-700"></div>

        {/* host */}
        <div className="flex items-center space-x-4">
          <Avatar
            hasChecked
            hasCheckedClass="w-4 h-4 -top-0.5 right-0.5"
            sizeClass="h-14 w-14"
            radius="rounded-full"
          />
          <div>
            <a className="block text-xl font-medium" href="##">
              پدرام یکتازاده
            </a>
            <div className="mt-1.5 flex items-center text-sm text-neutral-500 dark:text-neutral-400">
              <StartRating renderReviewCount={false} />
              <span className="mx-2">·</span>
              <span>
                <PersianNumber>12</PersianNumber> تجربه فعال
              </span>
            </div>
          </div>
        </div>

        {/* desc */}
        <span className="block text-neutral-6000 dark:text-neutral-300">
          پدرام، تور لیدری حرفه‌ای است که تورهای گردشگری و بومگردی شگفت‌انگیز را
          در شمال ایران، به ویژه در شهرهای رشت، ماسال، لاهیجان و لنگرود ارائه
          می‌دهد. او با همت و اراده به همراهی با مردم محلی، تجربه‌های منحصر به
          فردی از زیبایی‌ها و معماری‌های شمالی به مسافران ارائه می‌دهد و با
          برنامه‌ریزی دقیق، ایمنی و راحتی مسافرین را به‌عنوان اولویت‌های خود
          قرار می‌دهد. تجربه‌ی فراموش‌نشدنی و مسئولیت‌پذیری اجتماعی در سفرها، از
          دیگر ویژگی‌های ممتاز اوست. با پدرام به عنوان راهنما و همراه، سفری
          خاطره‌انگیز و پر از هیجان را تجربه خواهید کرد.
        </span>

        {/* info */}
        <div className="block text-neutral-500 dark:text-neutral-400 space-y-2.5">
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
            <span>
              <PersianNumber>5</PersianNumber> سال سابقه لیدری
            </span>
          </div>
          {/* <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
              />
            </svg>
            <span>Response rate - 100%</span>
          </div>
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>

            <span>Fast response - within a few hours</span>
          </div> */}
        </div>

        {/* == */}
        <div className="border-b w-14 border-neutral-200 dark:border-neutral-700"></div>
        <div>
          <ButtonSecondary href="##">
            مشاهده همه برنامه های فعال این لیدر
          </ButtonSecondary>
        </div>
      </div>
    );
  };

  const renderSection6 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Reviews (23 reviews)</h2>
        <div className="border-b w-14 border-neutral-200 dark:border-neutral-700"></div>

        {/* Content */}
        <div className="space-y-5">
          <FiveStartIconForRate iconClass="w-6 h-6" className="space-x-0.5" />
          <div className="relative">
            <Input
              fontClass=""
              sizeClass="h-16 px-4 py-3"
              rounded="rounded-3xl"
              placeholder="Share your thoughts ..."
            />
            <ButtonCircle
              className="absolute transform -translate-y-1/2 right-2 top-1/2"
              size=" w-12 h-12 "
            >
              <ArrowRightIcon className="w-5 h-5" />
            </ButtonCircle>
          </div>
        </div>

        {/* comment */}
        <div className="divide-y divide-neutral-100 dark:divide-neutral-800">
          <CommentListing className="py-8" />
          <CommentListing className="py-8" />
          <CommentListing className="py-8" />
          <CommentListing className="py-8" />
          <div className="pt-8">
            <ButtonSecondary>View more 20 reviews</ButtonSecondary>
          </div>
        </div>
      </div>
    );
  };

  const renderSection7 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Location</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            San Diego, CA, United States of America (SAN-San Diego Intl.)
          </span>
        </div>
        <div className="border-b w-14 border-neutral-200 dark:border-neutral-700" />

        {/* MAP */}
        <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3">
          <div className="overflow-hidden rounded-xl">
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyAGVJfZMAKYfZ71nzL_v5i3LjTTWnCYwTY",
              }}
              yesIWantToUseGoogleMapApiInternals
              defaultZoom={15}
              defaultCenter={{
                lat: 55.9607277,
                lng: 36.2172614,
              }}
            >
              <LocationMarker lat={55.9607277} lng={36.2172614} />
            </GoogleMapReact>
          </div>
        </div>
      </div>
    );
  };

  const renderSection8 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">سایر جزئیات قابل توجه</h2>
        <div className="border-b w-14 border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">استرداد و کنسلی</h4>
          <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
            این تور قابل استرداد و کنسلی نمیباشد.
          </span>
        </div>
        <div className="border-b w-14 border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">شرایط شرکت کنندگان</h4>
          <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
            سن مجاز برای شرکت در این تور از 18 سال به بالا میباشد، از 14 سال تا
            18 سال همراه بودن والدین الزامی میباشد و برای پایینتر از این سن این
            تور قابل ارائه نیست.
          </span>
        </div>
        <div className="border-b w-14 border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">توضیحات تکمیلی</h4>
          <div className="prose sm:prose">
            <ul className="mt-3 space-y-2 text-neutral-500 dark:text-neutral-400">
              <li>پوشش مناسب جهت مقابله با تغییرات آب و هوایی منطقه</li>
              <li>استفاده از کفش مناسب برای پیاده‌روی در مناطق جنگلی</li>
              <li>حمل چادر، لوازم شخصی و لوازم بهداشتی ضروری</li>
              <li>عدم استفاده از زباله‌ریزی در طبیعت و حفظ نظافت محیط</li>
            </ul>
          </div>
        </div>
      </div>
    );
  };

  const renderSidebar = () => {
    return (
      <div className="shadow-xl listingSectionSidebar__wrap">
        {/* PRICE */}
        <div className="flex justify-between">
          <span>
            <PersianNumber className="text-3xl font-semibold" separated={true}>
              1300000
            </PersianNumber>
            <span className="ml-2 text-sm font-light text-slate-400">
              تومان
            </span>
          </span>
          <span>هر نفر</span>
        </div>

        {/* FORM */}
        <form className="flex flex-col border divide-y sm:flex-row sm:divide-y-0 sm:divide-x divide-neutral-200 dark:divide-neutral-700 border-neutral-200 dark:border-neutral-700 rounded-3xl ">
          <div className="flex-1">
            {/* <ExperiencesDateSingleInput
              defaultValue={selectedDate}
              anchorDirection={"right"}
              fieldClassName="p-4"
              className="h-full"
            /> */}
            <div className="flex items-center flex-1 p-4 space-x-3 text-left">
              <div className="text-neutral-300 dark:text-neutral-400">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="nc-icon-field"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                  />
                </svg>
              </div>
              <div className="flex-grow">
                <span className="block font-semibold xl:text-lg">
                  <PersianNumber separated={false}>14</PersianNumber> مرداد{" "}
                  <PersianNumber separated={false}>1403</PersianNumber>
                </span>
                <span className="block mt-1 text-sm font-light leading-none text-neutral-400">
                  تاریخ
                </span>
              </div>
            </div>
          </div>
          <div className="flex-1 listingExperiencesDetailPage__GuestsInput">
            {/* <GuestsInput
              fieldClassName="p-4"
              defaultValue={{
                guestAdults: 1,
                guestChildren: 2,
                guestInfants: 0,
              }}
              hasButtonSubmit={false}
            /> */}
            <div className="flex items-center flex-1 p-4 space-x-3 text-left">
              <div className="text-neutral-300 dark:text-neutral-400">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="nc-icon-field"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"
                  />
                </svg>
              </div>
              <div className="flex-grow">
                <span className="block font-semibold xl:text-lg">
                  <PersianNumber separated={false}>3</PersianNumber> نفر
                </span>
                <span className="block mt-1 text-sm font-light leading-none text-neutral-400">
                  تعداد افراد
                </span>
              </div>
            </div>
          </div>
        </form>

        {/* SUM */}
        <div className="flex flex-col space-y-4">
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>
              <PersianNumber>3</PersianNumber>
              <span className="text-xl">ₓ</span> نفر
            </span>

            <span>
              <PersianNumber separated={true}>1300000</PersianNumber> تومان
            </span>
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>مالیات</span>
            <span>
              <PersianNumber separated={true}>0</PersianNumber> تومان
            </span>
          </div>
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>جمع کل</span>
            <span>
              <PersianNumber separated={true}>1300000</PersianNumber> تومان
            </span>
          </div>
        </div>

        {/* SUBMIT */}
        <ButtonPrimary href={"/checkout"}>رزرو</ButtonPrimary>
      </div>
    );
  };

  return (
    <div
      className={`ListingDetailPage nc-ListingExperiencesDetailPage ${className}`}
      data-nc-id="ListingExperiencesDetailPage"
    >
      {/* SINGLE HEADER */}
      <>
        <header className="container rounded-md 2xl:px-14 sm:rounded-xl">
          <div className="relative grid grid-cols-4 gap-1 sm:gap-2">
            <div
              className="relative col-span-3 row-span-3 overflow-hidden rounded-md cursor-pointer sm:rounded-xl"
              onClick={() => handleOpenModal(0)}
            >
              <NcImage
                containerClassName="absolute inset-0"
                className="object-cover w-full h-full rounded-md sm:rounded-xl"
                src={PHOTOS[0]}
              />
              <div className="absolute inset-0 transition-opacity opacity-0 bg-neutral-900 bg-opacity-20 hover:opacity-100"></div>
            </div>
            {PHOTOS.filter((_, i) => i >= 1 && i < 4).map((item, index) => (
              <div
                key={index}
                className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                  index >= 2 ? "block" : ""
                }`}
              >
                <NcImage
                  containerClassName="aspect-w-4 aspect-h-3"
                  className="object-cover w-full h-full rounded-md sm:rounded-xl "
                  src={item || ""}
                />

                {/* OVERLAY */}
                <div
                  className="absolute inset-0 transition-opacity opacity-0 cursor-pointer bg-neutral-900 bg-opacity-20 hover:opacity-100"
                  onClick={() => handleOpenModal(index + 1)}
                />
              </div>
            ))}

            <div
              className="absolute z-10 hidden px-4 py-2 cursor-pointer md:flex md:items-center md:justify-center left-3 bottom-3 rounded-xl bg-neutral-100 text-neutral-500 hover:bg-neutral-200"
              onClick={() => handleOpenModal(0)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-5 h-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                />
              </svg>
              <span className="ml-2 text-sm font-medium text-neutral-800">
                مشاهده همه تصاویر
              </span>
            </div>
          </div>
        </header>
        {/* MODAL PHOTOS */}
        <ModalPhotos
          imgs={PHOTOS}
          isOpen={isOpen}
          onClose={handleCloseModal}
          initFocus={openFocusIndex}
          uniqueClassName="nc-ListingExperiencesDetailPage__modalPhotos"
        />
      </>

      {/* MAIn */}
      <main className="container relative z-10 flex flex-col mb-10 mt-11 lg:flex-row">
        {/* CONTENT */}
        <div className="w-full space-y-8 lg:w-3/5 xl:w-2/3 lg:pr-10 lg:space-y-10">
          {renderSection1()}
          {renderSection2()}
          {renderSection3()}
          {/* {renderSectionCheckIndate()} */}
          {renderSection5()}
          {/* {renderSection6()} */}
          {/* {renderSection7()} */}
          {renderSection8()}
        </div>

        {/* SIDEBAR */}
        <div className="flex-grow hidden lg:block mt-14 lg:mt-0">
          <div className="sticky top-28">{renderSidebar()}</div>
        </div>
      </main>

      {/* STICKY FOOTER MOBILE */}
      <MobileFooterSticky />

      {/* OTHER SECTION */}
      {/* <div className="container py-24 lg:py-32">
        
        <div className="relative py-16">
          <BackgroundSection />
          <SectionSliderNewCategories
            heading="Explore by types of stays"
            subHeading="Explore houses based on 10 types of stays"
            categoryCardType="card5"
            itemPerRow={5}
            sliderStyle="style2"
            uniqueClassName="ListingExperiencesDetailPage"
          />
        </div>

        
        <SectionSubscribe2 className="pt-24 lg:pt-32" />
      </div> */}
    </div>
  );
};

export default ListingExperiencesDetailPage;
