import React, { FC } from "react";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { Link } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";

export interface ForgotPasswordProps {
  className?: string;
}

const redirectButtons = [
  {
    name: "ورود به سیستم",
    href: "/login",
  },
];

const ForgotPassword: FC<ForgotPasswordProps> = ({ className = "" }) => {
  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>جادونی | بازیابی کلمه عبور </title>
      </Helmet>
      <div className="container pb-24 lg:pb-32" style={{ minHeight: "700px" }}>
        <h2 className="my-20 flex items-center text-xl leading-[115%] md:text-2xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          بازیابی <span className="ml-4 text-secondary-400">کلمه عبور</span>
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          <div className="grid gap-3">
            {redirectButtons.map((item, index) => (
              <a
                key={index}
                href={item.href}
                className="nc-will-change-transform flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 32 32"
                  className="flex-shrink-0 w-5 h-5 text-neutral-500 dark:text-neutral-400"
                >
                  <path
                    fill="currentColor"
                    d="M16 5c-3.855 0-7 3.145-7 7c0 2.41 1.23 4.55 3.094 5.813C8.527 19.343 6 22.883 6 27h2c0-4.43 3.57-8 8-8s8 3.57 8 8h2c0-4.117-2.527-7.656-6.094-9.188A7.024 7.024 0 0 0 23 12c0-3.855-3.145-7-7-7zm0 2c2.773 0 5 2.227 5 5s-2.227 5-5 5s-5-2.227-5-5s2.227-5 5-5z"
                  ></path>
                </svg>

                <h3 className="flex-grow text-sm font-medium text-center text-neutral-700 dark:text-neutral-300 sm:text-sm">
                  {item.name}
                </h3>
              </a>
            ))}
          </div>
          {/* OR */}
          <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 text-sm font-medium bg-white dark:text-neutral-400 dark:bg-neutral-900">
              بازیابی کلمه عبور
            </span>
            <div className="absolute left-0 w-full transform -translate-y-1/2 border top-1/2 border-neutral-100 dark:border-neutral-800"></div>
          </div>
          {/* FORM */}
          <form className="grid grid-cols-1 gap-6" action="#" method="post">
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                آدرس ایمیل
              </span>
              <Input
                type="email"
                placeholder="example@example.com"
                className="mt-1 text-right"
              />
            </label>
            <ButtonPrimary type="submit">بازیابی کلمه عبور</ButtonPrimary>
          </form>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            نیاز به کمک دارید؟ {` `}
            <Link to="/contact">تماس با ما</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
