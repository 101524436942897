import React, { useEffect, useState } from "react";
import { AnchorDirectionShape, FocusedInputShape } from "react-dates";
import { DateRangePicker } from "@mohsen_saremi/react-dates";
import { DateRage } from "./StaySearchForm";
import { FC } from "react";
import useWindowSize from "hooks/useWindowResize";
import useNcId from "hooks/useNcId";
import moment from "moment";
import momentJalaali from "moment-jalaali";

export interface StayDatesRangeInputProps {
  defaultValue: DateRage;
  defaultFocus?: FocusedInputShape | null;
  onChange?: (data: DateRage) => void;
  onFocusChange?: (focus: FocusedInputShape | null) => void;
  className?: string;
  fieldClassName?: string;
  wrapClassName?: string;
  numberOfMonths?: number;
  anchorDirection?: AnchorDirectionShape;
}

const StayDatesRangeInput: FC<StayDatesRangeInputProps> = ({
  defaultValue,
  onChange,
  defaultFocus = null,
  onFocusChange,
  fieldClassName = "[ nc-hero-field-padding--small ]",
  wrapClassName = "divide-y divide-neutral-200 dark:divide-neutral-700 lg:divide-y-0 md:border-l md:border-r border-neutral-200 dark:border-neutral-700 lg:border-none",
  numberOfMonths,
  anchorDirection,
  className = "",
}) => {
  const [focusedInput, setFocusedInput] = useState(defaultFocus);
  const [stateDate, setStateDate] = useState(defaultValue);
  const startDateId = useNcId();
  const endDateId = useNcId();
  const windowSize = useWindowSize();

  useEffect(() => {
    setStateDate(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    setFocusedInput(defaultFocus);
  }, [defaultFocus]);

  useEffect(() => {
    if (onChange) {
      onChange(stateDate);
    }
  }, [stateDate.startDate, stateDate.endDate]); // Update the dependencies of the useEffect

  useEffect(() => {
    momentJalaali.locale("fa");
    momentJalaali.loadPersian({
      dialect: "persian-modern",
      usePersianDigits: true,
    });
  });

  const handleDateFocusChange = (focus: FocusedInputShape | null) => {
    setFocusedInput(focus);
    onFocusChange && onFocusChange(focus);
  };

  const renderInputCheckInDate = () => {
    const focused = focusedInput === "startDate";
    return (
      <div
        className={`relative flex items-center flex-1 ${fieldClassName} space-x-3 cursor-pointer ${
          focused ? "nc-hero-field-focused--2" : " "
        }`}
      >
        <div className="flex-1">
          <span className="block font-semibold">
            {stateDate.startDate
              ? momentJalaali(stateDate.startDate).format("jDD jMMMM")
              : "از تاریخ"}
          </span>
          <span className="block mt-1 text-sm font-light leading-none text-neutral-400">
            {stateDate.startDate ? "از تاریخ" : `شروع از تاریخ`}
          </span>
        </div>
      </div>
    );
  };

  const renderInputCheckOutDate = () => {
    const focused = focusedInput === "endDate";
    return (
      <div
        className={`relative flex items-center flex-1 ${fieldClassName} space-x-3 cursor-pointer ${
          focused ? "nc-hero-field-focused--2" : " "
        }`}
      >
        <div className="flex-1">
          <span className="block font-semibold">
            {stateDate.endDate
              ? momentJalaali(stateDate.endDate).format("jDD jMMMM")
              : "تا تاریخ"}
          </span>
          <span className="block mt-1 text-sm font-light leading-none text-neutral-400">
            {stateDate.endDate ? "تا تاریخ" : `شروع تا تاریخ`}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`StayDatesRangeInput relative flex-shrink-0 flex z-10 ${className} ${
        !!focusedInput ? "nc-date-focusedInput" : "nc-date-not-focusedInput"
      }`}
    >
      <div className="absolute inset-0 flex">
        <DateRangePicker
          startDate={stateDate.startDate}
          endDate={stateDate.endDate}
          focusedInput={focusedInput}
          onDatesChange={(date: any) => setStateDate(date)}
          onFocusChange={handleDateFocusChange}
          numberOfMonths={
            numberOfMonths || (windowSize.width <= 1024 ? 1 : undefined)
          }
          startDateId={startDateId}
          endDateId={endDateId}
          daySize={windowSize.width > 500 ? 38 : undefined}
          orientation={"horizontal"}
          showClearDates
          noBorder
          hideKeyboardShortcutsPanel
          anchorDirection={"left"}
          customArrowIcon={<div />}
          reopenPickerOnClearDates
          isRTL={true}
          renderDayContents={(day: any) =>
            day ? momentJalaali(day).format("jD") : ""
          }
          renderMonthElement={({ month }: { month: any }) =>
            momentJalaali(month).format("jMMMM jYYYY")
          }
          calendarSystem="jalali"
          // stateDateWrapper={momentJalaali}
          defaultInitialVisibleMonth={momentJalaali()}
        />
      </div>

      <div className={`grid grid-cols-2 flex-1 relative  ${wrapClassName}`}>
        {renderInputCheckInDate()}

        {renderInputCheckOutDate()}
      </div>
    </div>
  );
};

export default StayDatesRangeInput;
