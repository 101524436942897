import React, { FC } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { Link } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";

export interface PageLoginProps {
  className?: string;
}

const loginSocials = [
  {
    name: "ثبت نام کاربر جدید",
    href: "/signup",
    icon: facebookSvg,
  },
  // {
  //   name: "Continue with Twitter",
  //   href: "#",
  //   icon: twitterSvg,
  // },
  // {
  //   name: "Continue with Google",
  //   href: "#",
  //   icon: googleSvg,
  // },
];

const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {
  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>جادونی | ورود به سیستم </title>
      </Helmet>
      <div className="container pb-24 lg:pb-32" style={{ minHeight: "700px" }}>
        <h2 className="my-20 flex items-center text-xl leading-[115%] md:text-2xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          ورود به <span className="ml-4 text-secondary-400">جادونی</span>
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          <div className="grid gap-3">
            {loginSocials.map((item, index) => (
              <a
                key={index}
                href={item.href}
                className="nc-will-change-transform flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
              >
                {/* <img
                  className="flex-shrink-0"
                  src={item.icon}
                  alt={item.name}
                /> */}

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 32 32"
                  className="flex-shrink-0 w-5 h-5 text-neutral-500 dark:text-neutral-400"
                >
                  <path
                    fill="currentColor"
                    d="M12 2C8.145 2 5 5.145 5 9c0 2.41 1.23 4.55 3.094 5.813C4.527 16.343 2 19.883 2 24h2c0-4.43 3.57-8 8-8c1.375 0 2.656.36 3.781.969A8.007 8.007 0 0 0 14 22c0 4.406 3.594 8 8 8c4.406 0 8-3.594 8-8c0-4.406-3.594-8-8-8a7.964 7.964 0 0 0-4.688 1.531a10.134 10.134 0 0 0-1.406-.719A7.024 7.024 0 0 0 19 9c0-3.855-3.145-7-7-7zm0 2c2.773 0 5 2.227 5 5s-2.227 5-5 5s-5-2.227-5-5s2.227-5 5-5zm10 12c3.324 0 6 2.676 6 6s-2.676 6-6 6s-6-2.676-6-6s2.676-6 6-6zm-1 2v3h-3v2h3v3h2v-3h3v-2h-3v-3z"
                  ></path>
                </svg>

                <h3 className="flex-grow text-sm font-medium text-center text-neutral-700 dark:text-neutral-300 sm:text-sm">
                  {item.name}
                </h3>
              </a>
            ))}
          </div>
          {/* OR */}
          <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 text-sm font-medium bg-white dark:text-neutral-400 dark:bg-neutral-900">
              ورود به سیستم
            </span>
            <div className="absolute left-0 w-full transform -translate-y-1/2 border top-1/2 border-neutral-100 dark:border-neutral-800"></div>
          </div>
          {/* FORM */}
          <form className="grid grid-cols-1 gap-6" action="#" method="post">
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                آدرس ایمیل
              </span>
              <Input
                type="email"
                placeholder="example@example.com"
                className="mt-1 text-right"
              />
            </label>
            <label className="block">
              <span className="flex items-center justify-between text-neutral-800 dark:text-neutral-200">
                کلمه عبور
                <Link to="/forgot-pass" className="text-sm">
                  کلمه عبور را فراموش کردید؟
                </Link>
              </span>
              <Input type="password" className="mt-1 text-right" />
            </label>
            <Link to="/account">
              <ButtonPrimary type="submit">ورود به سیستم</ButtonPrimary>
            </Link>
          </form>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            هنوز ثبت نام نکرده اید؟ {` `}
            <Link to="/signup">ایجاد اکانت</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageLogin;
