import React from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import CommonLayout from "./CommonLayout";
import PersianNumber from "shared/localize/PersianNumber";
import { Link } from "react-router-dom";

const AccountBilling = () => {
  // Sample data for purchase history
  const purchaseHistory = [
    {
      id: 1,
      paymentId: "12345",
      title: "تور ییلاق سوباتان",
      guests: 2,
      date: "1402-08-23",
      price: 1200000,
    },
    {
      id: 2,
      paymentId: "12345",
      title: "تور یک روزه ماسال",
      guests: 2,
      date: "1402-09-15",
      price: 1600000,
    },
    {
      id: 3,
      paymentId: "12345",
      title: "تور کوهنوردی الاسبلانکا",
      guests: 2,
      date: "1402-10-28",
      price: 800000,
    },
  ];

  return (
    <div>
      <CommonLayout>
        <div className="space-y-6 sm:space-y-8" style={{ minHeight: "700px" }}>
          {/* HEADING */}
          <h2 className="text-3xl font-semibold">سوابق خریدهای شما</h2>
          <div className="border-b w-14 border-neutral-200 dark:border-neutral-700"></div>
          <div className="max-w-2xl">
            {/* LIST */}
            <ul className="divide-y divide-gray-200 dark:divide-gray-700">
              {purchaseHistory.map((purchase) => (
                <li key={purchase.id} className="py-4 sm:py-6">
                  <div className="flex flex-col sm:flex-row sm:items-baseline sm:justify-between">
                    <div className="text-sm font-medium text-gray-900 dark:text-white">
                      {purchase.title}
                    </div>
                    <div className="flex items-center mt-2 sm:mt-0 sm:ml-4">
                      <div className="text-sm text-gray-500 dark:text-gray-400">
                        <PersianNumber>{purchase.date}</PersianNumber>
                      </div>
                      <div className="ml-4 text-sm text-gray-500 dark:text-gray-400">
                        <PersianNumber>{purchase.guests}</PersianNumber>{" "}
                        <span className="text-sm">نفر</span>
                      </div>
                      <div className="ml-4 text-sm text-gray-500 dark:text-gray-400">
                        <PersianNumber>{purchase.price}</PersianNumber>{" "}
                        <span className="text-sm">تومان</span>
                      </div>
                      <div className="flex-grow ml-4 text-right">
                        <Link to="#">
                          <button className="h-8 px-4 text-sm text-indigo-100 transition-colors duration-150 bg-indigo-700 rounded-lg focus:shadow-outline hover:bg-indigo-800 dark:bg-indigo-600 dark:hover:bg-indigo-700">
                            مشاهده
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountBilling;
