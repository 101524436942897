import React, { useEffect, useState } from "react";
import {
  DayPickerRangeController,
  isInclusivelyAfterDay,
} from "@mohsen_saremi/react-dates";
import { FC } from "react";
import { DateRage } from "components/HeroSearchForm/StaySearchForm";
import momentJalaali from "moment-jalaali";
import moment from "moment";

export interface StayDatesRangeInputProps {
  defaultValue: DateRage;
  defaultFocus?: string | null;
  onChange?: (data: DateRage) => void;
  onFocusChange?: (focus: string | null) => void;
  className?: string;
}

const StayDatesRangeInput: FC<StayDatesRangeInputProps> = ({
  defaultValue,
  onChange,
  defaultFocus = "startDate",
  onFocusChange,
  className = "",
}) => {
  const [focusedInput, setFocusedInput] = useState(defaultFocus);
  const [stateDate, setStateDate] = useState(defaultValue);

  useEffect(() => {
    setStateDate(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    setFocusedInput(defaultFocus);
  }, [defaultFocus]);

  useEffect(() => {
    momentJalaali.locale("fa");
    momentJalaali.loadPersian({
      dialect: "persian-modern",
      usePersianDigits: true,
    });
  });

  return (
    <div>
      <div className="p-5">
        <span className="block text-xl font-semibold sm:text-2xl">
          تعیین تاریخ
        </span>
      </div>
      <div
        className={` relative flex-shrink-0 flex z-10 ${className} ${
          !!focusedInput ? "nc-date-focusedInput" : "nc-date-not-focusedInput"
        }`}
      >
        <DayPickerRangeController
          startDate={stateDate.startDate}
          endDate={stateDate.endDate}
          onDatesChange={(date: DateRage) => {
            // if stateDate.startDate and stateDate.endDate and date.startDate and date.endDate is not null then set date.endDate to null
            if (
              !!stateDate.startDate &&
              !!stateDate.endDate &&
              !!date.startDate &&
              !!date.endDate
            ) {
              date.endDate = null;
            }

            setStateDate(date);
            onChange && onChange(date);
          }}
          focusedInput={focusedInput}
          onFocusChange={(focused: string) => {
            // set focusedInput state to focusedInput value (if null set it to "startDate") and call onFocusChange callback
            setFocusedInput(focused || "startDate");
            onFocusChange && onFocusChange(focused);
          }}
          initialVisibleMonth={() =>
            !!stateDate.startDate ? stateDate.startDate : momentJalaali()
          }
          hideKeyboardShortcutsPanel={false}
          orientation="vertical"
          daySize={48}
          isOutsideRange={(day: any) => {
            return !isInclusivelyAfterDay(day, momentJalaali());
          }}
          verticalHeight={420}
          renderDayContents={(day: any) =>
            day ? momentJalaali(day).format("jD") : ""
          }
          renderMonthElement={({ month }: { month: any }) =>
            momentJalaali(month).format("jMMMM jYYYY")
          }
          calendarSystem="jalali"
          isRTL={true}
        />
      </div>
    </div>
  );
};

export default StayDatesRangeInput;
