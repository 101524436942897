import React, { FC, useState } from "react";
import ExperiencesSearchForm from "./ExperiencesSearchForm";
import StaySearchForm from "./StaySearchForm";
import RentalCarSearchForm from "./RentalCarSearchForm";
import FlightSearchForm from "./FlightSearchForm";

export type SearchTab = "Stays" | "Experiences" | "Cars" | "Flights";

export interface HeroSearchFormProps {
  className?: string;
  currentTab?: SearchTab;
  currentPage?: SearchTab;
  visibleTabs?: SearchTab[];
}

const HeroSearchForm: FC<HeroSearchFormProps> = ({
  className = "",
  currentTab = "Experiences",
  currentPage,
  visibleTabs = ["Experiences"],
}) => {
  const tabs: Record<SearchTab, string> = {
    Stays: "ویلا و اقامتگاه",
    Experiences: "انواع تجربه گردشگری",
    Cars: "Cars",
    Flights: "Flights",
  };

  const [tabActive, setTabActive] = useState<SearchTab>(currentTab);

  const renderTab = () => {
    return (
      <ul className="flex ml-2 space-x-5 overflow-x-auto sm:ml-6 md:ml-20 sm:space-x-8 lg:space-x-11 hiddenScrollbar">
        {visibleTabs.map((tab) => {
          const active = tab === tabActive;
          return (
            <li
              onClick={() => setTabActive(tab)}
              className={`flex-shrink-0 flex items-center cursor-pointer text-4xl lg:text-base font-bold mr-1 ${
                active
                  ? ""
                  : "text-neutral-400 hover:text-slate-300 dark:hover:text-slate-300"
              } `}
              key={tab}
            >
              {active && (
                <span className="block w-2.5 h-2.5 rounded-full bg-neutral-800 dark:bg-neutral-100 mr-2" />
              )}
              <span>{tabs[tab]}</span>
            </li>
          );
        })}
      </ul>
    );
  };

  const renderForm = () => {
    const isArchivePage = !!currentPage && !!currentTab;
    switch (tabActive) {
      case "Stays":
        return visibleTabs.includes("Stays") ? (
          <StaySearchForm haveDefaultValue={isArchivePage} />
        ) : null;
      case "Experiences":
        return visibleTabs.includes("Experiences") ? (
          // <ExperiencesSearchForm haveDefaultValue={isArchivePage} />
          <StaySearchForm haveDefaultValue={isArchivePage} />
        ) : null;
      case "Cars":
        return visibleTabs.includes("Cars") ? (
          <RentalCarSearchForm haveDefaultValue={isArchivePage} />
        ) : null;
      case "Flights":
        return visibleTabs.includes("Flights") ? (
          <FlightSearchForm haveDefaultValue={isArchivePage} />
        ) : null;
      default:
        return null;
    }
  };

  return (
    <div
      className={`nc-HeroSearchForm w-full max-w-6xl py-5 lg:py-0 ${className}`}
      data-nc-id="HeroSearchForm"
    >
      {renderTab()}
      {renderForm()}
    </div>
  );
};

export default HeroSearchForm;
