import React, { FC, useEffect } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { DEMO_EXPERIENCES_LISTINGS } from "data/listings";
import { ExperiencesDataType } from "data/types";
import StartRating from "components/StartRating/StartRating";
import { Link } from "react-router-dom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import Badge from "shared/Badge/Badge";
import Avatar from "shared/Avatar/Avatar";
import PersianNumber from "shared/localize/PersianNumber";
import momentJalaali from "moment-jalaali";

export interface ExperiencesCardHProps {
  className?: string;
  data?: ExperiencesDataType;
}

const DEMO_DATA: ExperiencesDataType = DEMO_EXPERIENCES_LISTINGS[0];

const ExperiencesCardH: FC<ExperiencesCardHProps> = ({
  className = "",
  data = DEMO_DATA,
}) => {
  const {
    galleryImgs,
    address,
    title,
    href,
    like,
    saleOff,
    isAds,
    price,
    reviewStart,
    reviewCount,
    author,
    id,
    date,
    listingCategory,
  } = data;

  useEffect(() => {
    momentJalaali.locale("fa");
    momentJalaali.loadPersian({
      dialect: "persian-modern",
      usePersianDigits: true,
    });
  });

  const renderSliderGallery = () => {
    return (
      <div className="relative flex-shrink-0 w-full overflow-hidden md:w-72">
        <GallerySlider
          ratioClass="aspect-w-12 aspect-h-9 md:aspect-h-11"
          galleryImgs={galleryImgs}
          uniqueID={`ExperiencesCardH_${id}`}
          href={href}
        />
        {/* <BtnLikeIcon isLiked={like} className="absolute right-3 top-3" />
        {saleOff && <SaleOffBadge className="absolute left-3 top-3" />} */}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className="flex flex-col flex-grow p-3 sm:p-5">
        <div className="space-y-2">
          <div className="flex items-center space-x-2">
            {/* {isAds && <Badge name="ADS" color="green" />} */}
            <h2 className="text-lg font-medium capitalize">
              <span className="line-clamp-1">{title}</span>
            </h2>
          </div>
          <div className="flex items-center space-x-4 text-sm text-neutral-500 dark:text-neutral-400">
            {/* <StartRating reviewCount={reviewCount} point={reviewStart} />
            <span>· </span> */}
            <Badge color="blue" name={listingCategory.name} />
            {/* <div className="flex items-center">
              <span className="hidden text-base sm:inline-block">
                <i className="las la-map-marked"></i>
              </span>
              <span className="sm:ml-2"> {address}</span>
            </div> */}

            <div className="flex items-center">
              <span className="inline-block text-base">
                <i className="las la-calendar"></i>
              </span>
              <span className="sm:ml-2">
                {momentJalaali(date).format("dddd jDD jMMMM jYYYY")}
              </span>
              <span className="ml-2 font-bold">ساعت</span>
              <PersianNumber className="ml-1">16:30</PersianNumber>
            </div>
          </div>
        </div>
        {/* <div className="my-4 border-b w-14 border-neutral-100 dark:border-neutral-800"></div> */}
        <div className="mt-4 text-sm text-neutral-500 dark:text-neutral-400">
          <span className="line-clamp-3">
            اگر به دنبال تجربهای بینظیر از گردشگری در مناطق طبیعی و عشایری
            هستید، تور گردشگری عشایر سوباتان را از دست ندهید. این تور به شما
            فرصتی عالی میدهد تا به دنیایی از زیباییها و خاطرهسازیهای بینظیر
            دسترسی داشته باشید. با همراهی راهنمایان مجرب و حرفهای، سفری عمیق به
            ییلاقهای زیبا و خوشآبوهوا در سوباتان را تجربه خواهید کرد. این
            ییلاقها با پوشش شقایق و ابری، خاطره دلنشینی را برای شما به همراه
            خواهند داشت. در این تجربه گردشگری همراه با عشایر سوباتان، در کلبههای
            چوبی سنتی اقامت کرده و شبها را با تماشای آسمان پرستاره در کنار آتش
            سپری خواهید کرد. این لحظاتی است که هیچگاه فراموش نخواهید کرد
          </span>
        </div>
        {/* <div className="flex items-center mt-4 space-x-8 ">
          <div className="flex items-center space-x-2">
            <i className="text-lg las la-clock"></i>
            <span className="text-sm text-neutral-500 dark:text-neutral-400">
              ۱ روز
            </span>
          </div>
          <div className="flex items-center space-x-2">
            <i className="text-lg las la-user"></i>
            <span className="text-sm text-neutral-500 dark:text-neutral-400">
              ظرفیت ۶ نفر
            </span>
          </div>
        </div> */}
        <div className="mb-4 border-b mt-11 w-14 border-neutral-100 dark:border-neutral-800"></div>
        <div className="flex items-end justify-between">
          <div className="flex items-center space-x-5 text-sm text-neutral-700 dark:text-neutral-300">
            {/* <div className="flex flex-col items-center space-y-3 text-center sm:flex-row sm:space-y-0 sm:text-left sm:space-x-3 ">
              <i className="text-2xl las la-calendar"></i>
              <span className="">
                <span className="mr-2">تاریخ شروع</span>
                <span>{momentJalaali(date).format("jDD jMMM jYYYY")}</span>
              </span>
            </div> */}
            <div className="flex flex-col items-center space-y-3 text-center sm:flex-row sm:space-y-0 sm:text-left sm:space-x-1 ">
              <i className="text-2xl las la-clock"></i>
              <span className="">
                <PersianNumber>4</PersianNumber> روز
              </span>
            </div>
            <div className="flex flex-col items-center space-y-3 text-center sm:flex-row sm:space-y-0 sm:text-left sm:space-x-1 ">
              <i className="text-2xl las la-user-friends"></i>
              <span className="">
                ظرفیت <PersianNumber>1</PersianNumber> نفر
              </span>
            </div>
            {/* <div className="flex flex-col items-center space-y-3 text-center sm:flex-row sm:space-y-0 sm:text-left sm:space-x-3 ">
              <i className="text-2xl las la-language"></i>
              <span className="">فارسی</span>
            </div> */}
            {/* <i className="text-lg las la-angle-double-left"></i>
            <span className="text-sm text-neutral-500 dark:text-neutral-400">
              طبیعت گردی
            </span> */}
            {/* <Avatar imgUrl={author.avatar} userName={author.displayName} /> */}
            {/* <span className="hidden sm:inline-block">
              <span className="hidden sm:inline"></span>
               {author.displayName}
            </span> */}
          </div>
          <span className="text-base font-semibold text-secondary-700">
            {price}
            {` `}
            <span className="text-sm font-normal text-neutral-500 dark:text-neutral-400">
              / هر نفر
            </span>
          </span>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-ExperiencesCardH group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-xl transition-shadow will-change-transform ${className}`}
      data-nc-id="ExperiencesCardH"
    >
      <Link to={href} className="absolute inset-0" />
      <div className="md:flex md:flex-row">
        {renderSliderGallery()}
        {renderContent()}
      </div>
    </div>
  );
};

export default ExperiencesCardH;
