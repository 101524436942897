import { Tab } from "@headlessui/react";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import React, { FC, Fragment, useState, useEffect, ChangeEvent } from "react";
import visaPng from "images/vis.png";
import mastercardPng from "images/mastercard.svg";
import Input from "shared/Input/Input";
import Label from "components/Label/Label";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import NcImage from "shared/NcImage/NcImage";
import StartRating from "components/StartRating/StartRating";
import NcModal from "shared/NcModal/NcModal";
import ModalSelectDate from "components/ModalSelectDate";
import moment from "moment";
import { DateRage } from "components/HeroSearchForm/StaySearchForm";
import converSelectedDateToString from "utils/converSelectedDateToString";
import ModalSelectGuests from "components/ModalSelectGuests";
import { GuestsObject } from "components/HeroSearchForm2Mobile/GuestsInput";
import PersianNumber from "shared/localize/PersianNumber";
import momentJalaali from "moment-jalaali";

export interface CheckOutPageProps {
  className?: string;
}

interface PassengerInfo {
  phoneNumber?: string;
  fullName: string;
  nationalCode: string;
  validFullName: boolean;
  validPhoneNumber: boolean;
  validNationalCode: boolean;
}

const CheckOutPage: FC<CheckOutPageProps> = ({ className = "" }) => {
  const [rangeDates, setRangeDates] = useState<DateRage>({
    startDate: moment().add(1, "day"),
    endDate: moment().add(5, "days"),
  });
  const [numPassengers, setNumPassengers] = useState<number>(3);
  const [passengerInfo, setPassengerInfo] = useState<PassengerInfo[]>([
    {
      fullName: "",
      nationalCode: "",
      validFullName: true,
      validPhoneNumber: true,
      validNationalCode: true,
    },
  ]);

  const handleInputChange = (
    index: number,
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    const updatedPassengers = passengerInfo.map((passenger, i) =>
      i === index ? { ...passenger, [name]: value } : passenger
    );

    setPassengerInfo(updatedPassengers);
    // validateInputs(); // This ensures the validation status is updated on input change
  };

  const handleNumPassengersChange = (newNumPassengers: number) => {
    setNumPassengers(newNumPassengers);
    if (newNumPassengers > passengerInfo.length) {
      const emptyPassengersToAdd = new Array(
        newNumPassengers - passengerInfo.length
      ).fill({
        fullName: "",
        nationalCode: "",
        validFullName: true,
        validPhoneNumber: true,
        validNationalCode: true,
      });
      setPassengerInfo([...passengerInfo, ...emptyPassengersToAdd]);
    } else if (newNumPassengers < passengerInfo.length) {
      const updatedPassengerInfo = passengerInfo.slice(0, newNumPassengers);
      setPassengerInfo(updatedPassengerInfo);
    }
  };

  useEffect(() => {
    momentJalaali.locale("fa");
    momentJalaali.loadPersian({
      dialect: "persian-modern",
      usePersianDigits: true,
    });
    handleNumPassengersChange(numPassengers);
  }, [numPassengers]);

  function validateNationalCode(input: string): boolean {
    if (!/^\d{10}$/.test(input)) return false;

    const check = parseInt(input[9]);
    let sum = 0;
    for (let i = 0; i < 9; ++i) {
      sum += parseInt(input[i]) * (10 - i);
    }
    sum %= 11;

    return (sum < 2 && check === sum) || (sum >= 2 && check + sum === 11);
  }

  const validateInputs = () => {
    const updatedPassengers = passengerInfo.map((passenger, index) => {
      const isValidFullName = passenger.fullName.trim() !== "";
      const isValidPhoneNumber =
        index === 0
          ? !!passenger.phoneNumber && passenger.phoneNumber?.trim() !== ""
          : true;
      const isValidNationalCode =
        passenger.nationalCode.trim() !== "" &&
        validateNationalCode(passenger.nationalCode);

      return {
        ...passenger,
        validFullName: isValidFullName,
        validPhoneNumber: isValidPhoneNumber,
        validNationalCode: isValidNationalCode,
      };
    });

    setPassengerInfo(updatedPassengers);
    return updatedPassengers.every(
      (passenger) =>
        passenger.validFullName &&
        passenger.validPhoneNumber &&
        passenger.validNationalCode
    );
  };

  const renderPassengerForms = () => {
    return passengerInfo.map((passenger, index) => (
      <div key={index} className="pt-8">
        <span className="block mb-4 font-semibold text-md text-slate-500">
          مشخصات گردشگر شماره {index + 1}
        </span>
        <div className="flex flex-col space-y-5 sm:flex-row sm:space-x-5 sm:space-y-0">
          {index === 0 && (
            <div className="flex-1 space-y-3">
              <Label>شماره همراه</Label>
              <Input
                name="phoneNumber"
                type="text"
                value={passenger.phoneNumber}
                onChange={(e) => handleInputChange(index, e)}
                className={`shadow-sm ${
                  !passenger.validPhoneNumber ? "bg-red-200" : ""
                }`}
              />
            </div>
          )}

          <div className="flex-1 space-y-3">
            <Label>نام و نام خانوادگی</Label>
            <Input
              type="text"
              name="fullName"
              value={passenger.fullName}
              onChange={(e) => handleInputChange(index, e)}
              className={`shadow-sm ${
                !passenger.validFullName ? "bg-red-200" : ""
              }`}
            />
          </div>
          <div className="flex-1 space-y-3">
            <Label>کد ملی</Label>
            <Input
              type="text"
              name="nationalCode"
              value={passenger.nationalCode}
              onChange={(e) => handleInputChange(index, e)}
              className={`shadow-sm ${
                !passenger.validNationalCode ? "bg-red-200" : ""
              }`}
            />
          </div>
        </div>
      </div>
    ));
  };

  const renderSidebar = () => {
    return (
      <div className="flex flex-col w-full px-0 space-y-6 shadow-sm sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 sm:space-y-8 sm:p-2 xl:p-2">
        <div className="flex flex-col sm:flex-row sm:items-center">
          <div className="flex-shrink-0 w-full sm:w-32">
            <div className="overflow-hidden aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl">
              <NcImage src="https://a0.muscache.com/im/pictures/lombard/MtTemplate-1435866-media_library/original/38d6b5ea-abcc-4876-acb4-e5b79586c37c.jpeg?im_w=1200" />
            </div>
          </div>
          <div className="flex-grow py-2 space-y-3 sm:pl-3 sm:pr-1">
            <div>
              <span className="block mt-1 text-base font-medium">
                تور بومگردی ماسال
              </span>
            </div>

            <span className="block text-sm text-neutral-500 dark:text-neutral-400">
              <div className="flex items-center justify-between">
                <span className="">
                  <span className="inline-block text-base">
                    <i className="las la-calendar"></i>
                  </span>
                  <span className="sm:ml-2">
                    {momentJalaali().format("dddd jDD jMMMM jYYYY")}
                  </span>
                </span>
                <span>
                  <span className="">ساعت</span>
                  <PersianNumber className="ml-2">16:30</PersianNumber>
                </span>
              </div>
            </span>
            <span className="block text-neutral-500 dark:text-neutral-400">
              <div className="flex items-center justify-between">
                <span className="text-xs">قیمت</span>
                <span>
                  <PersianNumber
                    className="text-lg font-semibold"
                    separated={true}
                  >
                    2000000
                  </PersianNumber>
                  <span className="pl-1 text-xs">تومان</span>
                </span>
              </div>
            </span>
          </div>
        </div>
        <div className="flex flex-col px-2 pb-5 space-y-4">
          <h3 className="text-xl font-semibold">جزئیات قیمت</h3>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>
              <PersianNumber>3</PersianNumber>
              <span className="text-xl">ₓ</span> نفر
            </span>

            <span>
              <PersianNumber separated={true}>6000000</PersianNumber> تومان
            </span>
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>مالیات</span>
            <span>
              <PersianNumber separated={true}>0</PersianNumber> تومان
            </span>
          </div>
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>جمع کل</span>
            <span>
              <PersianNumber separated={true}>6000000</PersianNumber> تومان
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderMain = () => {
    return (
      <div className="flex flex-col w-full px-0 space-y-8 sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 sm:p-6 xl:p-8">
        <h2 className="text-3xl font-semibold lg:text-4xl">
          تکمیل اطلاعات و خرید
        </h2>
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        <div>
          <div>
            <h3 className="text-2xl font-semibold">تور بومگردی ماسال</h3>
            <NcModal
              renderTrigger={(openModal) => (
                <span
                  onClick={() => openModal()}
                  className="block mt-1 underline cursor-pointer lg:hidden"
                >
                  مشاهده جزئیات
                </span>
              )}
              renderContent={renderSidebar}
              modalTitle="جزئیات تجربه گردشگری"
            />
          </div>

          <div className="flex flex-col mt-6 border divide-y shadow-sm bg-slate-50 dark:bg-slate-800 border-neutral-200 dark:border-neutral-700 rounded-3xl sm:flex-row sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700">
            <div className="flex items-center flex-1 p-4 space-x-3 text-left">
              <div className="text-neutral-300 dark:text-neutral-400">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="nc-icon-field"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                  />
                </svg>
              </div>
              <div className="flex-grow">
                <span className="block font-semibold xl:text-lg">
                  <span className="">
                    {momentJalaali().format("dddd jDD jMMMM jYYYY")}
                  </span>
                  <span className="ml-2 font-bold text-slate-500">ساعت</span>
                  <PersianNumber className="ml-1">16:30</PersianNumber>
                </span>
                <span className="block mt-1 text-sm font-light leading-none text-neutral-400">
                  تاریخ و زمان شروع
                </span>
              </div>
            </div>

            <div className="flex items-center flex-1 p-4 space-x-3 text-left">
              <div className="text-neutral-300 dark:text-neutral-400">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="nc-icon-field"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                  />
                </svg>
              </div>
              <div className="flex-grow">
                <span className="block font-semibold xl:text-lg">
                  <span className="">
                    {momentJalaali()
                      .add(2, "days")
                      .format("dddd jDD jMMMM jYYYY")}
                  </span>
                  <span className="ml-2 font-bold text-slate-500">ساعت</span>
                  <PersianNumber className="ml-1">18:45</PersianNumber>
                </span>
                <span className="block mt-1 text-sm font-light leading-none text-neutral-400">
                  تاریخ و زمان اتمام
                </span>
              </div>
            </div>
          </div>

          <span className="block mt-8 text-sm text-neutral-500 dark:text-neutral-400">
            <span className="inline-block text-base">
              <i className="las la-map-marked"></i>
              <span className="ml-2">آدرس مبدا:</span>
            </span>
            <span className="sm:ml-2">
              گیلان، رشت، خیابان معلم، خیابان شماره 12، کوچه اول، روبه روی کافه
              کوک
            </span>
          </span>
          <span className="block mt-2 text-sm text-neutral-500 dark:text-neutral-400">
            <span className="inline-block text-base">
              <i className="las la-calendar"></i>
            </span>
            <span className="ml-2">
              <span className="">
                {momentJalaali().format("dddd jDD jMMMM jYYYY")}
              </span>
              <span className="ml-2 font-bold">ساعت</span>
              <PersianNumber className="ml-1">16:30</PersianNumber>
            </span>
          </span>
        </div>

        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>

        <div>
          <h3 className="text-xl font-semibold">ورود اطلاعات گردشگرها</h3>
          <div className="my-5 border-b w-14 border-neutral-200 dark:border-neutral-700"></div>

          {renderPassengerForms()}

          <div className="mt-20">
            <div className="text-center">
              <ButtonPrimary
                onClick={() => {
                  validateInputs();
                }}
                className="w-full"
              >
                تایید و پرداخت
              </ButtonPrimary>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-CheckOutPage ${className}`} data-nc-id="CheckOutPage">
      <main className="container flex flex-col-reverse mb-24 mt-11 lg:mb-32 lg:flex-row">
        <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 ">{renderMain()}</div>
        <div className="flex-grow hidden lg:block">
          <div className="sticky top-28 ">{renderSidebar()}</div>
        </div>
      </main>
    </div>
  );
};

export default CheckOutPage;
