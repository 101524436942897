import StartRating from "components/StartRating/StartRating";
import React, { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import NcImage from "shared/NcImage/NcImage";
import PersianNumber from "shared/localize/PersianNumber";
import momentJalaali from "moment-jalaali";

export interface PayPageProps {
  className?: string;
}

const PayPage: FC<PayPageProps> = ({ className = "" }) => {
  const renderContent = () => {
    return (
      <div className="flex flex-col w-full px-0 space-y-8 sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 sm:p-6 xl:p-8">
        <h2 className="text-xl font-semibold lg:text-2xl">
          تبریک میگم فرایند پرداخت شما با موفقیت انجام شد!
        </h2>

        <div className="border-b border-dashed border-neutral-200 dark:border-neutral-700"></div>

        {/* ------------------------ */}
        <div className="space-y-6">
          <h3 className="text-2xl font-semibold">تیکت خریداری شده</h3>
          <div className="flex flex-col sm:flex-row sm:items-center">
            <div className="flex-shrink-0 w-full sm:w-40">
              <div className="overflow-hidden aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl">
                <NcImage src="https://images.pexels.com/photos/3493777/pexels-photo-3493777.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" />
              </div>
            </div>
            <div className="pt-5 space-y-3 sm:pb-5 sm:px-5">
              <div>
                <span className="text-sm font-medium line-clamp-1">
                  تور 1.5 روزه ماسال
                </span>
                <span className="mt-1 text-sm text-neutral-500 dark:text-neutral-40 line-clamp-2">
                  گر به دنبال تجربهای بینظیر از گردشگری در مناطق طبیعی و عشایری
                  هستید، تور گردشگری عشایر سوباتان را از دست ندهید. این تور به
                  شما فرصتی عالی میدهد تا به دنیایی از زیباییها و خاطرهسازیهای
                  بینظیر دسترسی داشته باشید. با همراهی راهنمایان مجرب و حرفهای،
                  سفری عمیق به ییلاقهای زیبا و خوشآبوهوا در سوباتان را تجربه
                  خواهید کرد. این ییلاقها با پوشش شقایق و ابری، خاطره دلنشینی را
                  برای شما به همراه خواهند داشت. در این تجربه گردشگری همراه با
                  عشایر سوباتان، در کلبههای چوبی سنتی اقامت کرده و شبها را با
                  تماشای آسمان پرستاره در کنار آتش سپری خواهید کرد. این لحظاتی
                  است که هیچگاه فراموش نخواهید کرد. تجربه پیادهروی در ارتفاعات
                  جنگل لیسار (آلچالیق) و بازدید از آبشارهای زیبا و بارش شهابی
                  برساووشی، یکی از هیجانانگیزترین لحظات این سفر خواهد بود.
                </span>
                <span className="block mt-1 text-base font-medium sm:text-lg">
                  برای <PersianNumber>3 </PersianNumber>نفر
                </span>
              </div>
              <span className="block text-sm text-neutral-500 dark:text-neutral-400">
                گیلان، ماسال
              </span>
            </div>
          </div>

          <div className="flex flex-col mt-6 border divide-y shadow-sm bg-slate-50 dark:bg-slate-800 border-neutral-200 dark:border-neutral-700 rounded-3xl sm:flex-row sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700">
            <div className="flex items-center flex-1 p-4 space-x-3 text-left">
              <div className="text-neutral-300 dark:text-neutral-400">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="nc-icon-field"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                  />
                </svg>
              </div>
              <div className="flex-grow">
                <span className="block font-semibold xl:text-lg">
                  <span className="">
                    {momentJalaali().format("dddd jDD jMMMM jYYYY")}
                  </span>
                  <span className="ml-2 font-bold text-slate-500">ساعت</span>
                  <PersianNumber className="ml-1">16:30</PersianNumber>
                </span>
                <span className="block mt-1 text-sm font-light leading-none text-neutral-400">
                  تاریخ و زمان شروع
                </span>
              </div>
            </div>

            <div className="flex items-center flex-1 p-4 space-x-3 text-left">
              <div className="text-neutral-300 dark:text-neutral-400">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="nc-icon-field"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                  />
                </svg>
              </div>
              <div className="flex-grow">
                <span className="block font-semibold xl:text-lg">
                  <span className="">
                    {momentJalaali()
                      .add(2, "days")
                      .format("dddd jDD jMMMM jYYYY")}
                  </span>
                  <span className="ml-2 font-bold text-slate-500">ساعت</span>
                  <PersianNumber className="ml-1">18:45</PersianNumber>
                </span>
                <span className="block mt-1 text-sm font-light leading-none text-neutral-400">
                  تاریخ و زمان اتمام
                </span>
              </div>
            </div>
          </div>

          <span className="block mt-8 text-sm text-neutral-500 dark:text-neutral-400">
            <span className="inline-block text-base">
              <i className="las la-map-marked"></i>
              <span className="ml-2">آدرس مبدا:</span>
            </span>
            <span className="sm:ml-2">
              گیلان، رشت، خیابان معلم، خیابان شماره 12، کوچه اول، روبه روی کافه
              کوک
            </span>
          </span>
          <span className="block mt-2 text-sm text-neutral-500 dark:text-neutral-400">
            <span className="inline-block text-base">
              <i className="las la-calendar"></i>
            </span>
            <span className="ml-2">
              <span className="">
                {momentJalaali().format("dddd jDD jMMMM jYYYY")}
              </span>
              <span className="ml-2 font-bold">ساعت</span>
              <PersianNumber className="ml-1">16:30</PersianNumber>
            </span>
          </span>
        </div>

        <div className="relative hidden pt-5 my-5 border-b-2 border-dashed border-neutral-400 sm:block">
          <div className="absolute border-r-2 border-dashed border-neutral-400 w-20 h-20 -mt-10 rounded-full -left-[77px] dark:bg-neutral-900 bg-white"></div>
          <div className="absolute border-l-2 border-dashed border-neutral-400 w-20 h-20 -mt-10 rounded-full -right-[77px] dark:bg-neutral-900 bg-white"></div>
        </div>

        <div className="relative visible block pt-5 my-5 border-b-2 border-dashed border-neutral-400 sm:hidden">
          <div className="absolute border-r-2 border-dashed border-neutral-400 w-5 h-5 -mt-2 rounded-full -left-[15px] dark:bg-neutral-900 bg-white"></div>
          <div className="absolute border-l-2 border-dashed border-neutral-400 w-5 h-5 -mt-2 rounded-full -right-[15px] dark:bg-neutral-900 bg-white"></div>
        </div>

        {/* ------------------------ */}
        <div className="space-y-6">
          <h3 className="text-2xl font-semibold">جزئیات تیکت</h3>
          <div className="flex flex-col space-y-4">
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">شماره رزرو</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                222-333-111
              </span>
            </div>
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">تاریخ رزرو</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                <span className="block font-semibold xl:text-lg">
                  <span className="">
                    {momentJalaali().format("dddd jDD jMMMM jYYYY")}
                  </span>
                  <span className="ml-2 font-bold text-slate-500">ساعت</span>
                  <PersianNumber className="ml-1">16:30</PersianNumber>
                </span>
              </span>
            </div>
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">جمع کل</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                <PersianNumber separated={true}>1300000</PersianNumber> تومان
              </span>
            </div>
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">شناسه پرداخت</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                166516546794
              </span>
            </div>
          </div>
        </div>
        <div>
          <ButtonPrimary
            className="w-full bg-transparent border-[1px] border-dashed border-neutral-700 mt-6  mb-6"
            href="/"
          >
            چاپ تیکت
          </ButtonPrimary>
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-PayPage ${className}`} data-nc-id="PayPage">
      <main className="container mb-24 mt-11 lg:mb-32 ">
        <div className="max-w-4xl mx-auto">{renderContent()}</div>
      </main>
    </div>
  );
};

export default PayPage;
